import React, { useReducer } from 'react'
import ClassicTextField from './ClassicTextField'
import { useTranslation } from '@kiway/shared/utils/translation'

const initState = {
  value: '',
  error: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return {
        ...state,
        value: action.payload,
      }
    case 'UPDATE_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

function EmailField({ ...props }) {
  const { t } = useTranslation()
  return (
    <ClassicTextField
      label={t('user:form.emailField')}
      name={'email'}
      {...props}
    />
  )
}

export function useEmailField({ ...props }) {
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  return [(
    <EmailField
      key="email"
      value={state.value}
      onChange={({ target: { value } }) => {
        dispatch({ type: 'UPDATE_VALUE', payload: value })
      }}
      error={state.error !== ''}
      helperText={state.error}
      {...props}
    />
  ), state.value, state.error]
}

export default EmailField
