import React from 'react'
import ClassicTextField from './ClassicTextField'
import { useTranslation } from '@kiway/shared/utils/translation'

function LastnameField({ ...props }) {
  const { t } = useTranslation()
  return (
    <ClassicTextField 
      label={t('file:addPatientModal.lastName')}
      name={'lastName'}
      {...props}
    />
  )
}

export default LastnameField